import { BonsaiNode } from '@mhe/ngx-bonsai';

import { BonsaiTree, TagLocation, TagNode } from '@mhe/reader/models';

export interface ILocationsView {
  currentSubjectNode: any
  currentTopicNode: any
  locationsTree: any
  casesPatternsTree: any
  expandedCasesPatterns: any
}

export interface TopicsState {
  topicsTree: BonsaiTree<BonsaiNode | TagNode>
  currentTopicNode: any
  currentSpineId: string
  selectedLocation: TagLocation | null
  selectedTopic: string | null
  expandedSubjects: Record<string, boolean>
  isLocationsView: boolean
  locationsView: ILocationsView
  initialSubjectId: string | undefined
  loading: boolean
  errorLoading: boolean
}

export const locationsViewInitialState: ILocationsView = {
  locationsTree: null,
  currentSubjectNode: null,
  currentTopicNode: null,
  expandedCasesPatterns: {},
  casesPatternsTree: null,
};

export const initialState: TopicsState = {
  topicsTree: {},
  currentTopicNode: {},
  currentSpineId: '',
  selectedLocation: null,
  selectedTopic: null,
  expandedSubjects: {},
  isLocationsView: false,
  locationsView: locationsViewInitialState,
  initialSubjectId: undefined,
  loading: false,
  errorLoading: false,
};
