import { Injectable } from '@angular/core';
import { BonsaiTreeComponent } from '@mhe/ngx-bonsai';
import { ExtendedComponentStore, logCatchError } from '@mhe/reader/common';
import { ofType } from '@ngrx/effects';
import { EMPTY, forkJoin, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  delay,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  BonsaiExpandedNodesMap,
  SEARCH_ROOT_BONSAI_NODE,
  SearchResult,
  SearchParams,
  SearchSubject,
} from '@mhe/reader/models';
import { SearchService } from '../search.service';
import { searchResultsToBonsaiTree } from '../utils/map-search-bonsai-tree';
import { SearchState, initialSearchState } from './search.state';
import * as actions from './search.actions';
import { EPubLoaderService } from '@mhe/reader/features/epub-loader';

@Injectable()
export class SearchStore extends ExtendedComponentStore<
SearchState,
actions.SearchActions
> {
  constructor(
    private readonly epubLoader: EPubLoaderService,
    private readonly searchService: SearchService,
  ) {
    super(initialSearchState);
  }

  /** selectors */
  readonly isReady$ = this.select((state) => state.ready);
  readonly searching$ = this.select(({ searching }) => searching);
  readonly searchContent$ = this.select((state) => state.searchContent);

  readonly results$ = this.select(({ results }) => results);
  // bonsai
  readonly resultsTree$ = this.select(this.results$, (results) =>
    searchResultsToBonsaiTree(results),
  );

  readonly expandedNodes$ = this.select((state) => state.expandedNodes);

  readonly min$ = this.select(({ minSearchLength }) => minSearchLength);

  readonly downloadPercent$ = this.select((state) => state.downloadPercent);

  /** updaters */
  readonly setReady = this.updater((state, ready: boolean) => ({
    ...state,
    ready,
  }));

  readonly setSearching = this.updater((state, searching: boolean) => ({
    ...state,
    searching,
  }));

  readonly setSearchContent = this.updater((state, searchContent: any[]) => ({
    ...state,
    searchContent,
  }));

  readonly setResults = this.updater((state, results: SearchResult[]) => ({
    ...state,
    results,
  }));

  readonly setDownloadPercent = this.updater(
    (state, downloadPercent: number) => ({
      ...state,
      downloadPercent,
    }),
  );

  readonly setMinSearchLength = this.updater(
    (state, minSearchLength: number) => ({
      ...state,
      minSearchLength,
    }),
  );

  // node expansion
  readonly toggleExpandedNode = this.updater((state, nodeId: string) => {
    const currentExpandedState = state.expandedNodes[nodeId];
    const expandedNodes = {
      ...state.expandedNodes,
      [nodeId]: !currentExpandedState,
    };

    return { ...state, expandedNodes };
  });

  readonly setNodeExpansion = this.updater(
    (state, nodes: BonsaiExpandedNodesMap) => {
      const expandedNodes = { ...state.expandedNodes, ...nodes };

      return { ...state, expandedNodes };
    },
  );

  readonly resetExpandedNodes = this.updater((state) => ({
    ...state,
    expandedNodes: {},
  }));

  /** action effects */
  private readonly _setSearchContent$ = this.effect(() =>
    this.actions$.pipe(
      ofType(actions.setSearchContent),
      delay(100), // prevents blocking drawer animation
      exhaustMap(({ spine, flatToc }) => {
        const content$ = this.epubLoader.loadSearchContent(spine, flatToc);
        const tapDownloadPercent = tap((results: SearchSubject[]) => {
          if (spine.length > 0) {
            // prevent potential divide by 0 error
            this.setDownloadPercent(
              Math.round((results.length / spine.length) * 100),
            );
          }
        });

        return forkJoin([content$.pipe(tapDownloadPercent)]).pipe(
          map(([docs]) => docs.sort((a, b) => a.index - b.index)),
          logCatchError('html load error', false),
        );
      }),
      tap((content) => this.setSearchContent([...content])),
      tap(() => this.setReady(true)),
      catchError(() => EMPTY),
    ),
  );

  /** effects */
  readonly search$ = this.effect((params$: Observable<SearchParams>) =>
    params$.pipe(
      tap(() => this.setSearching(true)),
      withLatestFrom(this.min$),
      switchMap(([params, min]) => this.searchResults$(params, min)),
      tap((results) => {
        this.setResults$(results);
        this.setSearching(false);
      }),
      catchError((error) => {
        this.setSearching(false);
        return throwError(error);
      }),
      logCatchError('search$'),
    ),
  );

  private readonly setResults$ = this.effect(
    (results$: Observable<SearchResult[]>) =>
      results$.pipe(
        tap((results) => this.setResults(results)),
        tap(() => this.resetExpandedNodes()),
        withLatestFrom(this.resultsTree$),
        map(([, tree]) => tree[SEARCH_ROOT_BONSAI_NODE]),
        map((root) => root.childIds?.[0]),
        filter((firstNode) => Boolean(firstNode)),
        tap((firstNode) => this.toggleExpandedNode(firstNode as string)),
        logCatchError('setResults$'),
      ),
  );

  readonly focusResults$ = this.effect((el$: Observable<BonsaiTreeComponent>) =>
    el$.pipe(
      withLatestFrom(this.resultsTree$, this.expandedNodes$),
      tap(([el, tree, expandedMap]) => {
        const root = tree[SEARCH_ROOT_BONSAI_NODE];
        const firstNodeId = root.childIds?.[0];
        const firstNode = tree[firstNodeId as string];
        const firstNodeExpanded = Boolean(expandedMap[firstNodeId as string]);

        if (firstNode?.childIds?.length && firstNodeExpanded) {
          const firstChildId = firstNode.childIds[0];
          el.focusNode(firstChildId);
        } else {
          el.focusNode(firstNodeId as string);
        }
      }),
      logCatchError('focusResults$'),
    ),
  );

  /** effect utils */
  private readonly searchResults$ = (
    search: SearchParams,
    min: number,
  ): Observable<SearchResult[]> =>
    search.query?.length < min
      ? of([] as SearchResult[])
      : this.searchService.searchEpubFiles(search);
}
