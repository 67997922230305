import {
  delay,
  distinctUntilKeyChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  AfterContentInit,
  AfterViewChecked,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';

import { NavigationStore } from '@mhe/reader/components/navigation';
import * as navigationActions from '@mhe/reader/components/navigation/state/navigation.actions';
import { BonsaiNode, BonsaiTreeComponent } from '@mhe/ngx-bonsai';
import { AlertType, ButtonPurpose, uuid } from '@mhe/ngx-shared';
import {
  Assessment,
  Bonsai,
  HeaderId,
  Payload,
  ReadiatorMessageTypes,
  TagLocation,
  TagNode,
  TagType,
} from '@mhe/reader/models';

import * as topicsActions from '@mhe/reader/components/topics/state/topics.actions';
import { TopicsStore } from '@mhe/reader/components/topics/state';
import { setAriaCurrentElement, setParentListAriaLabel } from '@mhe/reader/utils';
import { Observable, Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { EpubLibCFIService } from '@mhe/reader/features/annotation';
import { ReaderConfigStore } from '../reader/state';

@Component({
  selector: 'rdrx-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss'],
})
export class TopicsComponent implements AfterContentInit, AfterViewChecked, OnDestroy {
  readonly topicsActions = topicsActions;
  readonly rootNodeId = Bonsai.RootNode;
  readonly topicsTree$ = this.topicsStore.topicsTree$;
  @ViewChild(BonsaiTreeComponent, { read: ElementRef }) tree: ElementRef;
  activeLocationId$ = this.topicsStore.activeBonsaiNodeId$.pipe(shareReplay());
  activeTopicId$: Subject<string> = new Subject<string>();
  destroy$: Subject<boolean> = new Subject<boolean>();
  expandedSubjects$: Observable<Record<string, boolean>> =
    this.topicsStore.expandedSubjects$;

  isLocationsView$ = this.topicsStore.isLocationsView$;
  isTopicsTreeEmpty$ = this.topicsTree$.pipe(map((tree) => Object.keys(tree).length === 0));
  errorLoadingSubjects$ = this.topicsStore.errorLoadingSubjects$;
  locationsView$ = this.topicsStore.locationsView$;
  currentTopicNode: TagNode;
  buttonPurpose = ButtonPurpose;

  bbVideos: BonsaiNode | Record<string, any> = {
    _bonsaiTreeRoot: {
      id: '_bonsaiTreeRoot',
      childIds: [
        HeaderId.BB_VIDEO,
      ],
      title: 'b&b',
    },
    [HeaderId.BB_VIDEO]: {
      id: HeaderId.BB_VIDEO,
      title: 'B&B',
      header: true,
      collapsible: true,
      type: TagType.BB_VIDEO_H,
      childIds: [],
    },
  };

  haveBbVideos = false;

  public AlertType = AlertType;

  readonly subjectsError$ = this.topicsStore.topicsTree$.pipe(
    map((tree) => {
      return (
        !tree[Bonsai.RootNode] || tree[Bonsai.RootNode]?.childIds?.length === 0
      );
    }),
  );

  locationsTreeTemplate = {
    [Bonsai.RootNode]: {
      id: Bonsai.RootNode,
      childIds: [],
    },
  };

  casePatternTreeTemplate = {
    [Bonsai.RootNode]: {
      id: Bonsai.RootNode,
      childIds: [HeaderId.CASES, HeaderId.ALGORITHMS, HeaderId.PATTERNS],
      type: TagType.HEADER,
    },
    [HeaderId.CASES]: {
      id: HeaderId.CASES,
      childIds: [],
      title: this.translate.instant('contents.topics_cases'),
      type: TagType.HEADER,
      collapsible: true,
    },
    [HeaderId.ALGORITHMS]: {
      id: HeaderId.ALGORITHMS,
      childIds: [],
      title: this.translate.instant('contents.topics_algorithms'),
      type: TagType.HEADER,
      collapsible: true,
    },
    [HeaderId.PATTERNS]: {
      id: HeaderId.PATTERNS,
      childIds: [],
      title: this.translate.instant('contents.topics_pattern_recognition'),
      type: TagType.HEADER,
      collapsible: true,
    },
  };

  showTopicDetailsError: boolean;

  constructor(
    private readonly topicsStore: TopicsStore,
    private readonly navigationStore: NavigationStore,
    private readonly epubLibCFIService: EpubLibCFIService,
    private readonly translate: TranslateService,
    private readonly readerConfigStore: ReaderConfigStore,
  ) {}

  ngAfterContentInit(): void {
    const initializeLocationsView = this.initializeLocationsView();
    if (initializeLocationsView) initializeLocationsView.subscribe();
    this.initializeBoardsAndBeyond().subscribe();
  }

  ngAfterViewChecked(): void {
    setParentListAriaLabel(this.tree, 'Topics');
    this.isLocationsView$
      .pipe(
        filter((isLocationsView: boolean) => Boolean(isLocationsView)),
        switchMap(() => this.activeLocationId$),
        map((id: string) => id),
        takeUntil(this.destroy$),
      )
      .subscribe((id) => setAriaCurrentElement(id, this.tree));
  }

  showToggleFn(node: TagNode): boolean | undefined {
    return node.collapsible;
  }

  isLoading(node: TagNode): boolean | undefined {
    return node.loading;
  }

  initializeLocationsView(): Observable<any> {
    return this.topicsStore.initialSubjectId$?.pipe(
      withLatestFrom(
        this.topicsTree$,
        this.topicsStore.locationsView$,
        this.topicsStore.expandedSubjects$,
        this.readerConfigStore.cfi$,
      ),
      filter(
        ([dfaTopicUUID, topicsTree, locationsView, expandedSubjects, _]) => {
          return (
            !!dfaTopicUUID &&
            Object.values(topicsTree).length > 0 &&
            locationsView?.currentTopicNode?.id &&
            Object.values(expandedSubjects).length > 0
          );
        },
      ),
      delay(0),
      take(1),
      tap(([dfaTopicUUID, topicsTree, locationsView, _, cfi]) => {
        const tagNode = topicsTree[dfaTopicUUID as string] as TagNode;
        if (locationsView.currentTopicNode.id === dfaTopicUUID) {
          this.showLocationsView(tagNode, cfi);
          this.topicsStore.setInitialSubjectId(undefined);
        }
      }),
    );
  }

  toggleExpandedSubjects(subjectNodeId): void {
    this.topicsTree$
      .pipe(
        take(1),
        tap((topicsTree) => {
          const hasChildren = topicsTree[subjectNodeId].childIds?.length;
          if (!hasChildren) {
            this.topicsStore.setSubjectLoadingState({
              subjectId: subjectNodeId,
              loading: true,
            });
            this.topicsStore.dispatch(
              topicsActions.getLocations({ uuid: subjectNodeId }),
            );
          }
        }),
      )
      .subscribe();
    this.topicsStore.updateExpandedSubjects({ id: subjectNodeId });
  }

  toggleExpandedLocations = (headerNodeId): Subscription =>
    this.topicsStore.updateExpandedCasesPatterns({
      expandedCasesPatternsId: headerNodeId,
    });

  showLocationsView(node: TagNode, cfi?: string): void {
    this.topicsStore.setSelectedTopic(node.id);
    if (node.type === TagType.SUBJECT) {
      this.toggleExpandedSubjects(node.id);
      this.currentTopicNode = node;
      return;
    }
    if (node.type === TagType.TOPIC) {
      this.topicsStore.setCurrentTopicNode({ node });
    }

    this.currentTopicNode = node;
    if ((this.currentTopicNode.locations as TagLocation[]).length > 0) {
      const currentTagLocations = this.currentTopicNode
        .locations as TagLocation[];
      const location = cfi
        ? this.getLocationByCfi(
          cfi,
          this.currentTopicNode.locations as TagLocation[],
        )
        : currentTagLocations[0];
      cfi = cfi || location?.cfi;

      this.navigationStore.dispatch(
        navigationActions.navigateByCfi({ cfi, setFocus: true }),
      );
      this.topicsStore.dispatch(
        topicsActions.setSelectedLocation({ location }),
      );
    }

    this.showTopicDetailsError = this.currentTopicNode.locations?.length === 0;
    this.topicsStore.setLocationsView({ isLocationsView: true });

    this.topicsTree$
      .pipe(
        tap((topicsTree) => {
          this.topicsStore.setCurrentSubjectNode({
            node: topicsTree[(topicsTree[node.id] as TagNode).parent as string],
          });
          this.topicsStore.setLocationsTree({
            locationsTree: { ...this.locationsTreeTemplate },
          });
          this.topicsStore.setCasesPatternsAndAlgorithmsTree({
            casesPatternsTree: { ...this.casePatternTreeTemplate },
          });

          const allCasePatternAndAlgorithmNodes = {};
          const allLocationNodes = {};

          const topicsTreeNodes = Object.values(topicsTree);

          topicsTreeNodes.forEach((currentNode: TagNode) => {
            const isTopicMatch = this.currentTopicNode.id === currentNode.id;
            const isCasePatternOrAlgorithmMatch =
              this.currentTopicNode.id === currentNode.parent;
            const isTopic = currentNode.type === TagType.TOPIC;
            const isCase = currentNode.type === TagType.CASE;
            const isPattern = currentNode.type === TagType.PATTERN;

            const headerId = isPattern
              ? HeaderId.PATTERNS
              : isCase
                ? HeaderId.CASES
                : HeaderId.ALGORITHMS;

            if (isTopicMatch && isTopic) {
              const locationNodes = currentNode.locations?.map((location) => {
                const spineId = this.epubLibCFIService.getSpineId(location.cfi);

                const locationNode = {
                  locations: [location],
                  name: location.title,
                  parent: HeaderId.TOPICS,
                  type: TagType.TOPIC,
                  uuid: spineId,
                  id: spineId,
                  title: location.title,
                };

                allLocationNodes[locationNode.id] = locationNode;

                return locationNode;
              });

              const locationNodeIds = locationNodes?.map(
                (locationNode) => locationNode.id as HeaderId,
              ) as HeaderId[];
              this.topicsStore.setChildsLocationsTree({
                treeRoot: Bonsai.RootNode,
                childIds: [...locationNodeIds],
              });
            }

            if (isCasePatternOrAlgorithmMatch) {
              const locationNodes = currentNode.locations?.map((location) => {
                const spineId = this.epubLibCFIService.getSpineId(location.cfi);

                const locationNode = {
                  locations: [location],
                  name: currentNode.name,
                  parent: headerId,
                  type: currentNode.type,
                  uuid: spineId,
                  id: spineId,
                  title: currentNode.name,
                };

                // Ensure location node's ID is unique when multiple locations share the same spine
                if (!allCasePatternAndAlgorithmNodes[locationNode.id]) {
                  allCasePatternAndAlgorithmNodes[locationNode.id] =
                    locationNode;
                } else {
                  locationNode.id += '_' + uuid();
                  allCasePatternAndAlgorithmNodes[locationNode.id] =
                    locationNode;
                }

                return locationNode;
              });

              const locationNodeIds = locationNodes?.map(
                (locationNode) => locationNode.id,
              ) as string[];
              this.topicsStore.setChildsCasesPatternsAndAlgorithmsTree({
                treeRoot: headerId,
                childIds: [...locationNodeIds],
              });
            }
          });

          this.topicsStore.setCasesPatternsAndAlgorithmsTree({
            casesPatternsTree: allCasePatternAndAlgorithmNodes,
          });

          this.topicsStore.removeEmptyCasesAndPatterns();

          this.topicsStore.setLocationsTree({
            locationsTree: allLocationNodes,
          });
        }),
      )
      .subscribe();
  }

  hideLocationsView = (): void => {
    this.topicsStore.setLocationsView({ isLocationsView: false });
    this.topicsStore.resetCurrentLocationsView();
    this.topicsStore.selectedTopic$
      .pipe(
        take(1),
        tap((selectedTopic) => {
          this.activeTopicId$.next(selectedTopic as string);
        }),
        switchMap(() => this.topicsStore.expandedSubjects$),
        take(1),
        delay(0),
        tap(() => {
          const currentTopicsNodes = this.currentTopicNode
            ?.locations as TagLocation[];
          this.activeTopicId$.next(this.currentTopicNode.id);
          document
            .getElementById(`tree-node-${this.currentTopicNode.id}`)
            ?.scrollIntoView({ behavior: 'smooth' });
          this.topicsStore.setSelectedLocation(currentTopicsNodes[0]);
        }),
      )
      .subscribe();
  };

  handleNodeClick(node, preserveToCOpen = false): void {
    const isHeader = node.type === TagType.HEADER || node.type === TagType.BB_VIDEO_H;

    if (isHeader) {
      this.toggleExpandedLocations(node.id);

      return;
    }

    let isInternal = false;
    if (node.locations !== undefined && this.isInternal(node.locations[0])) {
      isInternal = true;
    }

    const payload = this.createPayload(node, isInternal);

    if (isInternal && !preserveToCOpen) {
      this.navigationStore.dispatch(
        navigationActions.navigateByCfi({
          cfi: node.locations[0].cfi,
          setFocus: true,
        }),
      );
      this.topicsStore.dispatch(
        topicsActions.selectInternalLocation({ location: node.locations[0] }),
      );
    }

    this.topicsStore.dispatch(topicsActions.emitReadiatorEvent({ payload }));
  }

  createPayload(node: TagNode, isInternal: boolean): Payload {
    let payload: Payload = {} as unknown as Payload;

    switch (node.type) {
      case TagType.SUBJECT:
        payload = {
          assessmentInstanceId: (node.assessments as Assessment[])[0]
            ?.instanceId,
          event: ReadiatorMessageTypes.ASSESSMENT_LAUNCH,
        };

        break;

      case TagType.TOPIC:
        payload = {
          cfi: (node.locations as TagLocation[])[0]?.cfi,
          epub: (node.locations as TagLocation[])[0]?.epub,
          event: isInternal
            ? ReadiatorMessageTypes.TOPIC_LAUNCH_ANALYTICS
            : ReadiatorMessageTypes.TOPIC_LAUNCH,
        };

        break;

      case TagType.CASE:
        payload = {
          epub: (node.locations as TagLocation[])[0]?.epub,
          cfi: (node.locations as TagLocation[])[0]?.cfi,
          event: isInternal
            ? ReadiatorMessageTypes.CASE_LAUNCH_ANALYTICS
            : ReadiatorMessageTypes.CASE_LAUNCH,
        };

        break;

      case TagType.PATTERN:
        payload = {
          epub: (node.locations as TagLocation[])[0]?.epub,
          cfi: (node.locations as TagLocation[])[0]?.cfi,
          event: isInternal
            ? ReadiatorMessageTypes.PATTERN_LAUNCH_ANALYTICS
            : ReadiatorMessageTypes.PATTERN_LAUNCH,
        };

        break;

      case TagType.ALGORITHM:
        payload = {
          epub: (node.locations as TagLocation[])[0]?.epub,
          cfi: (node.locations as TagLocation[])[0]?.cfi,
          event: isInternal
            ? ReadiatorMessageTypes.ALGORITHM_LAUNCH_ANALYTICS
            : ReadiatorMessageTypes.ALGORITHM_LAUNCH,
        };

        break;

      case TagType.BB_VIDEO:
        payload = {
          epub: (node.locations as TagLocation[])[0]?.epub,
          event: ReadiatorMessageTypes.BOARDS_AND_BEYOND_DFA_VIDEO,
          videoId: node.id,
          title: node.title,
          cadmoreId: node?.cadmoreId,
          topic: (node.locations as TagLocation[])[0]?.title,
        };

        break;

      default:
        console.error("createPayload: this shouldn't have happened...");

        break;
    }

    return payload;
  }

  isInternal(location: TagLocation): boolean {
    const url = window.location.href;
    const isInternal = url.includes(location.epub);

    return isInternal;
  }

  getLocationByCfi(cfi: string, locations: TagLocation[]): TagLocation {
    return locations.find((location) => location.cfi === cfi) as TagLocation;
  }

  initializeBoardsAndBeyond(): Observable<void> {
    return this.locationsView$.pipe(
      takeUntil(this.destroy$),
      map(locations => locations.currentTopicNode),
      filter(topicNode => Boolean(topicNode)),
      distinctUntilKeyChanged('id'),
      tap(topicNode => {
        // Clear videos for B&B subject
        if (this.bbVideos[HeaderId.BB_VIDEO].childIds.length) {
          this.bbVideos[HeaderId.BB_VIDEO].childIds = [];
        }

        const videos = topicNode.videos;
        if (!videos?.length) {
          this.haveBbVideos = false;
          return;
        }

        this.haveBbVideos = true;
        videos?.forEach(video => {
          this.bbVideos[HeaderId.BB_VIDEO].childIds.push(video.id);
          this.bbVideos[video.id] = {
            header: false,
            id: video?.id,
            title: video?.title,
            type: TagType.BB_VIDEO,
            cadmoreId: video?.cadmoreId,
            locations: topicNode.locations,
            collapsible: false,
            childIds: [],
          };
        });
      }),
    );
  }

  ngOnDestroy(): void {
    // This component is destroyed when the tab in the menu change
    this.destroy$.next(true);
    this.destroy$.complete();
    // Set to undefined since initial navigation to topic from LTI is complete
    this.topicsStore.setInitialSubjectId(undefined);
  }
}
