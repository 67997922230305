import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatSliderModule } from '@angular/material/slider';
import { ButtonModule } from '@mhe/ngx-shared';
import { TooltipModule } from '@mhe/ngx-shared/tooltip';
import { CommonDirectivesModule } from '@mhe/reader/common';
import { TranslateModule } from '@ngx-translate/core';

import { FontResizerComponent } from './font-resizer.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CommonDirectivesModule,
    // cdk
    A11yModule,
    // material
    MatMenuModule,
    MatSliderModule,
    // ngx-shared
    ButtonModule,
    TooltipModule,
    TranslateModule,
  ],
  declarations: [FontResizerComponent],
  exports: [FontResizerComponent],
})
export class FontResizerModule {}
