import { Inject, Injectable } from '@angular/core';
import { ExpandedNodesMap } from '@mhe/ngx-bonsai/lib/bonsai-tree/models';
import { ExtendedComponentStore } from '@mhe/reader/common';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap, withLatestFrom } from 'rxjs/operators';

import {
  AnnotationGroup,
  AnnotationListBonsaiNode,
  BonsaiTree,
  ROOT_BONSAI_NODE,
} from '@mhe/reader/models';
import { AnnotationListState, BatchDeleteState } from './annotation-list.state.abstract';
import { groupsToTree } from './groups-to-tree';

@Injectable()
export abstract class AnnotationListStore<
  T extends AnnotationListState,
  K extends Action,
> extends ExtendedComponentStore<T, K> {
  /** selectors **/
  readonly groups$: Observable<AnnotationGroup[]> = this.select(
    ({ groups }) => groups,
  );

  readonly batchDeleteState$: Observable<BatchDeleteState> = this.select(
    ({ userActions: { batchDelete } }) => batchDelete,
  );

  readonly tree$: Observable<BonsaiTree<AnnotationListBonsaiNode>> =
    this.select(this.groups$, (groups) => groupsToTree(groups));

  readonly expandedNodes$: Observable<ExpandedNodesMap> = this.select(
    ({ expandedNodes }) => expandedNodes,
  );

  readonly readonly$: Observable<boolean> = this.select(
    ({ readonly }) => readonly,
  );

  /** updators **/
  readonly setGroups = this.updater(
    (state, groups: AnnotationGroup[]): T => ({
      ...state,
      groups,
    }),
  );

  readonly mergeGroups = this.updater((state, groups: AnnotationGroup[]) => {
    return {
      ...state,
      groups:
        state.groups.length === 0
          ? groups
          : state.groups.map((group) => {
            const found = groups.find((g) => g.groupId === group.groupId);
            return found ?? group;
          }),
    };
  });

  readonly toggleExpandedNode = this.updater((state, nodeId: string) => {
    const currentExpandedState = state.expandedNodes[nodeId];
    const expandedNodes = {
      ...state.expandedNodes,
      [nodeId]: !currentExpandedState,
    };
    return { ...state, expandedNodes };
  });

  private readonly _setExpandedNodes = this.updater(
    (state, expandedNodes: ExpandedNodesMap) => ({
      ...state,
      expandedNodes,
    }),
  );

  readonly setReadonly = this.updater((state, readonly: boolean) => ({
    ...state,
    readonly,
  }));

  /** effects **/
  private readonly _cleanExpandedMap = this.effect(() =>
    this.tree$.pipe(
      withLatestFrom(this.expandedNodes$),
      tap(([tree, expandedNodes]) => {
        const rootNode: AnnotationListBonsaiNode = tree[ROOT_BONSAI_NODE];
        const chapterNodeIds = rootNode.childIds;
        const expandedNodeMap = chapterNodeIds?.reduce(
          (acc, chapterNodeId) => ({
            ...acc,
            [chapterNodeId]: expandedNodes[chapterNodeId] || false,
          }),
          {},
        );
        this._setExpandedNodes(expandedNodeMap as any);
      }),
    ),
  );

  readonly batchDeleteIdle = this.updater((state) => ({
    ...state,
    userActions: {
      ...state.userActions,
      batchDelete: BatchDeleteState.IDLE,
    },
  }));

  readonly batchDeleteStart = this.updater((state) => ({
    ...state,
    userActions: {
      ...state.userActions,
      batchDelete: BatchDeleteState.PENDING,
    },
  }));

  readonly batchDeleteSuccess = this.updater((state) => ({
    ...state,
    userActions: {
      ...state.userActions,
      batchDelete: BatchDeleteState.SUCCESS,
    },
  }));

  readonly batchDeleteCanceled = this.updater((state) => ({
    ...state,
    userActions: {
      ...state.userActions,
      batchDelete: BatchDeleteState.CANCELED,
    },
  }));

  constructor(@Inject('') initialState: T) {
    super(initialState);
  }
}
