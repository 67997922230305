/* eslint-disable max-len */
import { HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import {
  TranslateModule as NgxSharedTranslateModule,
  TranslateService as NgxSharedTranslateService,
} from '@mhe/ngx-shared';
import { TOKEN_SERVICE, TokenInterceptorModule } from '@mhe/ol-platform/auth';
import { InstrumentationModule } from '@mhe/ol-platform/instrumentation';

import { EffectsModule } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { LayoutModule } from '@angular/cdk/layout';
import { ReaderTranslateAdapter } from '@mhe/reader/adapters';
import { ConfigService } from '@mhe/reader/config';
import { loadLtiParameters, LtiService } from '@mhe/reader/lti';
import { StateModule } from '@mhe/reader/state/state.module';
import { I18nEffects } from '@mhe/reader/state/i18n/i18n.effects';
import { EpubUiViewerModule } from '@mhe/reader/components/epub-ui-viewer/epub-ui-viewer.module';
import { NotFoundComponent } from '@mhe/reader/components/not-found/not-found.component';
import { ReaderTokenService } from '@mhe/reader/tokens';
import { TimeoutComponent } from '@mhe/reader/components/timeout/timeout.component';
import { ReaderCoreModule } from '@mhe/reader/core/reader-core.module';
import { AccessDeniedComponent } from '@mhe/reader/components/access-denied/access-denied.component';

import { AppRoutingModule } from './app-routing.modules';
import { AppComponent } from './app.component';
import {
  loadConfigFactory,
  setDefaultLanguageFactory,
} from '@mhe/reader/factories';
import { PendoService } from '@mhe/reader/features/analytics';
import { MatDialogModule } from '@angular/material/dialog';
import { LIVE_ANNOUNCER_DEFAULT_OPTIONS } from '@angular/cdk/a11y';

@NgModule({
  imports: [
    MatDialogModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    InstrumentationModule,
    LayoutModule,
    ReaderCoreModule,
    NgxSharedTranslateModule.forRoot({
      provide: NgxSharedTranslateService,
      useClass: ReaderTranslateAdapter,
      deps: [TranslateService],
    }),
    TranslateModule.forRoot({}),
    StateModule,
    EffectsModule.forRoot([I18nEffects]),
    AppRoutingModule,
    EpubUiViewerModule,
    TokenInterceptorModule,
  ],
  declarations: [
    AppComponent,
    AccessDeniedComponent,
    NotFoundComponent,
    TimeoutComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: loadLtiParameters,
      deps: [Store, LtiService, Router, TOKEN_SERVICE, PendoService],
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: setDefaultLanguageFactory,
      deps: [TranslateService],
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: loadConfigFactory,
      deps: [Store, ConfigService],
    },
    { provide: TOKEN_SERVICE, useClass: ReaderTokenService },
    {
      provide: LIVE_ANNOUNCER_DEFAULT_OPTIONS,
      // Set Live Announcer to clear an aria-live text block after 2 seconds by default
      useValue: { duration: 2000 },
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
