/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  ConfigurableFocusTrap,
  ConfigurableFocusTrapFactory,
  LiveAnnouncer,
} from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSlider } from '@angular/material/slider';
import { ButtonPurpose } from '@mhe/ngx-shared';
import { TranslateService } from '@ngx-translate/core';
import { Subject, asyncScheduler } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  takeUntil,
  pairwise,
  startWith,
  filter,
} from 'rxjs/operators';

import { FontResizerStore } from './state/font-resizer.store';
import { GoogleAnalyticsService } from '@mhe/reader/features/analytics';

@Component({
  selector: 'reader-core-font-resizer',
  templateUrl: './font-resizer.component.html',
  styleUrls: ['./font-resizer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FontResizerComponent implements OnInit, OnDestroy {
  @ViewChild('fontResizeControl') control: ElementRef;
  @ViewChild('slider') slider: MatSlider;
  @ViewChild('trigger', { read: ElementRef }) trigger: ElementRef;

  readonly stopPropagationEvents = [
    'click',
    'keydown.tab',
    'keydown.shift.tab',
  ];

  readonly sliderControl = new FormControl(0);
  readonly max = 5;
  readonly min = -5;

  readonly val$ = this.sliderControl.valueChanges.pipe(
    map((val) => Number(val)),
    distinctUntilChanged(),
    shareReplay(1),
  );

  readonly increaseDisabled$ = this.val$.pipe(map((val) => val >= this.max));
  readonly decreaseDisabled$ = this.val$.pipe(map((val) => val <= this.min));
  readonly controlDisabled$ = this.fontResizerStore.disabled$;

  // expose import ref
  ButtonPurpose = ButtonPurpose;

  private focustrap: ConfigurableFocusTrap;
  private readonly destroy = new Subject();

  constructor(
    private readonly focustrapFactory: ConfigurableFocusTrapFactory,
    private readonly fontResizerStore: FontResizerStore,
    private readonly ga: GoogleAnalyticsService,
    private readonly liveAnnouncer: LiveAnnouncer,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.val$
      .pipe(takeUntil(this.destroy))
      .subscribe((val) => this.fontResizerStore.valueChange$(val));

    this.fontResizerStore.initialSliderValue$.subscribe((value) => {
      if (value !== null) {
        this.sliderControl.setValue(Number(value));
      } else {
        this.sliderControl.setValue(0);
      }
    });
    this.analyticsEmitChanges();
  }

  ngOnDestroy(): void {
    this.focustrap?.destroy();
    this.destroy.next(null);
    this.destroy.complete();
  }

  /** font-size */
  fontsizeValueChanged(event): void {
    this.sliderControl.setValue(event.value);
  }

  increase(): void {
    let val = this.sliderControl.value as number;
    val += 1;

    this.sliderControl.setValue(val);
    this.announceValueChange(val);
  }

  decrease(): void {
    let val = this.sliderControl.value as number;
    val -= 1;

    this.sliderControl.setValue(val);
    this.announceValueChange(val);
  }

  /** menu */
  open(): void {
    this.focustrap = this.focustrapFactory.create(this.control.nativeElement);
    // Wait until the overlay element fully load
    setTimeout(
      async() => await this.focustrap.focusInitialElementWhenReady(),
      250,
    );

    this.ga.event({ eventCategory: 'Font Resizer', eventAction: 'Show' });
  }

  close(): void {
    this.focustrap?.destroy();
    asyncScheduler.schedule(() => this.trigger.nativeElement.focus());

    this.ga.event({ eventCategory: 'Font Resizer', eventAction: 'Hide' });
  }

  /** a11y */
  private announceValueChange(val: any): void {
    const currentVal = this.translate.instant('font-resizer.current_value', {
      val,
    });
    void this.liveAnnouncer.announce(currentVal);
  }

  /** analytics */
  private analyticsEmitChanges(): void {
    const valHistory$ = this.val$.pipe(
      startWith(false),
      pairwise(),
      filter(([p, _c]) => Boolean(p)),
    );

    const eventAction$ = valHistory$.pipe(
      map(([p, c]) => ({
        eventAction: p < c ? 'Increase' : 'Decrease',
        eventValue: Number(c),
      })),
    );

    eventAction$.pipe(takeUntil(this.destroy)).subscribe((eventParams) => {
      this.ga.event({ eventCategory: 'Font Resizer', ...eventParams });
    });
  }
}
