import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ComponentEffects, logCatchError } from '@mhe/reader/common';
import { DoubleSpineItem, SpineItem, FORCE_RENDER_HASH } from '@mhe/reader/models';
import { ofType } from '@ngrx/effects';
import { combineLatest } from 'rxjs';
import { filter, map, shareReplay, tap, withLatestFrom } from 'rxjs/operators';

import { ReaderConfigStore } from '@mhe/reader/components/reader/state';
import { ReaderStore } from '../components/reader/state';
import * as readerActions from '../components/reader/state/reader.actions';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Injectable()
export class NavigationRoutingMediator extends ComponentEffects {
  private readonly readerActions$ = this.readerStore.actions$;

  constructor(
    private readonly location: Location,
    private readonly config: ReaderConfigStore,
    private readonly readerStore: ReaderStore,
    private readonly title: Title,
    private readonly liveAnnouncer: LiveAnnouncer,
  ) {
    super();
  }

  // navigation url
  private readonly urlRouteInfo$ = combineLatest([
    this.readerStore.integrateRouting$,
    this.readerActions$.pipe(ofType(readerActions.setSpineItem, readerActions.setDoubleSpineItem)),
  ]).pipe(
    filter(([routing]) => routing),
    map(([, action]) => ({
      spine: action.spineItem,
      hash: 'hash' in action ? action.hash : undefined,
    })),
    map(({ spine, hash }) => {
      let spineItem: SpineItem;
      const isdouble = ('left' || 'right') in spine;
      if (isdouble) {
        spine = spine as DoubleSpineItem;
        spineItem = spine.left ?? spine.right;
      } else {
        spineItem = spine as SpineItem;
      }
      return { spineItem, hash };
    }),
    shareReplay(),
  );

  private readonly updateRoute$ = this.effect(() =>
    this.urlRouteInfo$.pipe(
      tap(({ spineItem: { id, idref }, hash }) => {
        const path = this.location.path();
        const basepath = path.split('/').slice(0, 3);
        const baseroute = basepath.join('/');

        let route = `${baseroute}/${id || idref}`;
        if (hash && hash !== FORCE_RENDER_HASH) {
          route += hash;
        }

        this.location.replaceState(route);
      }),
      logCatchError('updateRoute$'),
    ),
  );

  private readonly updateTitle$ = this.effect(() => {
    let announcementTimeout;
    return this.urlRouteInfo$.pipe(
      withLatestFrom(this.readerStore.flatToc$, this.config.cfi$, this.config.titleOverride$),
      tap(([{ spineItem }, toc, cfi, titleOverride]) => {
        let newTitle: string;
        if (titleOverride) {
          newTitle = titleOverride;
        } else {
          const item = toc[spineItem.index];
          newTitle = item?.label ?? 'Reader | McGraw Hill';
        }
        this.title.setTitle(newTitle);
        // This prevent read all the title pages if the user press many times next/prev button
        if (announcementTimeout) {
          clearTimeout(announcementTimeout);
        }
        announcementTimeout = setTimeout(() => {
          void this.liveAnnouncer.announce(newTitle);
        }, 2000);
      }),
      logCatchError('updateTitle$'),
    );
  });
}
