import { ExpandedNodesMap } from '@mhe/ngx-bonsai/lib/bonsai-tree/models';
import { AnnotationGroup } from '@mhe/reader/models';

export interface AnnotationListState {
  expandedNodes: ExpandedNodesMap
  groups: AnnotationGroup[]
  readonly: boolean
  userActions: {
    batchDelete: BatchDeleteState
  }
}

export enum BatchDeleteState {
  IDLE,
  PENDING,
  SUCCESS,
  CANCELED,
}

export const initialAnnotationListState: AnnotationListState = {
  expandedNodes: {},
  groups: [],
  readonly: false,
  userActions: {
    batchDelete: BatchDeleteState.IDLE,
  },
};
