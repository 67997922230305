import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatSliderModule } from '@angular/material/slider';
import { ButtonModule } from '@mhe/ngx-shared';
import { TooltipModule } from '@mhe/ngx-shared/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { MenuItemDirective } from './overflow-menu/overflow-menu-item.directive';
import { OverflowMenuComponent } from './overflow-menu/overflow-menu.component';
import { ToolbarComponent } from './toolbar.component';
import { FontResizerModule } from '@mhe/reader/components/font-resizer';
import { NavigationModule } from '@mhe/reader/components/navigation';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    // cdk
    A11yModule,
    OverlayModule,
    // material
    MatMenuModule,
    MatSliderModule,
    // ngx-shared
    ButtonModule,
    TooltipModule,
    // reader features
    NavigationModule,
    FontResizerModule,
    MatProgressSpinnerModule,
  ],
  declarations: [ToolbarComponent, OverflowMenuComponent, MenuItemDirective],
  exports: [ToolbarComponent, MenuItemDirective],
  providers: [TitleCasePipe],
})
export class ToolbarModule { }
