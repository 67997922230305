<button
  mhe-button
  #trigger
  aria-haspopup="false"
  placement="bottom"
  [purpose]="ButtonPurpose.Icon"
  [matMenuTriggerFor]="fontSize"
  [matMenuTriggerRestoreFocus]="false"
  [mheTooltip]="'font-resizer.change_size' | translate"
  [disabled]="controlDisabled$ | async"
  (menuOpened)="open()"
  (menuClosed)="close()"
>
  <i class="dpg-icon dpg-icon-keyboard-letters"></i>
</button>

<mat-menu
  #fontSize="matMenu"
  role="dialog"
  class="dynamic-min font-resizer-menu"
  yPosition="above"
  xPosition="before"
  [aria-label]="'font-resizer.control_label' | translate"
>
  <div #fontResizeControl class="font-resizer-controls" [mheStopPropagation]="stopPropagationEvents">
    <button
      [attr.aria-label]="'font-resizer.decrease_size' | translate"
      class="btn btn-icon"
      [disabled]="decreaseDisabled$ | async"
      (click)="decrease()"
    >
      A
    </button>

    <!-- NOTE: The 'vertical' property no longer exists on Angular Material 15 -->
    <!-- Vertical sliders and inverted sliders are no longer supported, as they are no longer part of the Material Design spec. -->
    <!-- As a result, the invert and vertical properties have been removed.-->
    <mat-slider
      #ngSlider
      cdkFocusInitial
      class="mhe-a11y rdrx-slider"
      mheStopPropagation="keydown.arrowup,keydown.arrowdown"
      [attr.aria-label]="'font-resizer.slider_label' | translate"
      [attr.aria-valuetext]="'font-resizer.current_value' | translate: { val: ngSliderThumb.value }"
      [max]="max"
      [min]="min"
      step="1"
    >
      <input
        matSliderThumb
        #ngSliderThumb="matSliderThumb"
        [formControl]="sliderControl"
        (input)="fontsizeValueChanged({ source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value })"
      />
    </mat-slider>


    <button
      [attr.aria-label]="'font-resizer.increase_size' | translate"
      class="btn btn-icon"
      [disabled]="increaseDisabled$ | async"
      (click)="increase()"
    >
      A
    </button>
  </div>
</mat-menu>
