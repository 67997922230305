import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TooltipModule } from '@mhe/ngx-shared/tooltip';
import { AlertModule, ButtonModule, CheckboxModule, ModalModule } from '@mhe/ngx-shared';
import { CommonDirectivesModule } from '@mhe/reader/common';
import { TranslateModule } from '@ngx-translate/core';

import { HighlightIconComponent } from './highlight-icon/highlight-icon.component';
import { HighlightListComponent } from './highlight-list.component';
import { NgxBonsaiModule } from '@mhe/ngx-bonsai';
import {
  AnnotationListHeaderComponent,
} from '../annotation-list-header/annotation-list-header.component';
import {
  AnnotationListFooterComponent,
} from '../annotation-list-footer/annotation-list-footer.component';
import { AnnotationListModule } from '../annotation-list.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgxBonsaiModule,
    CommonDirectivesModule,
    AlertModule,
    ButtonModule,
    TooltipModule,
    MatProgressSpinnerModule,
    ModalModule,
    CheckboxModule,
    AnnotationListModule,
    AnnotationListHeaderComponent,
    AnnotationListFooterComponent,
  ],
  declarations: [
    HighlightListComponent,
    HighlightIconComponent,
  ],
  exports: [HighlightListComponent],
})
export class HighlightListModule { }
