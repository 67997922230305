import { ElementRef } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { TocItem, FlatTocItem } from '../models/epub';

export function flattenToc(
  items: TocItem[],
  flatItems: TocItem[] = [],
): TocItem[] {
  items.forEach((item) => {
    flatItems.push(item);
    if (item.subItems && item.subItems.length > 0) {
      flattenToc(item.subItems, flatItems);
    }
  });

  return flatItems;
}

export function flattenTocDictionary(
  items: TocItem[],
  flatToc: Record<number, FlatTocItem> = {},
  groupLabel?: string,
): Record<number, FlatTocItem> {
  for (const item of items) {
    const { spinePos, label, subItems } = item;
    groupLabel = groupLabel ?? label;

    if (!flatToc[spinePos]) {
      const fitem: FlatTocItem = { ...item, groupLabel };
      flatToc = { ...flatToc, [spinePos]: { ...fitem } };
    }

    if (subItems?.length) {
      flatToc = { ...flattenTocDictionary(subItems, flatToc, groupLabel) };
    }
  }

  return flatToc;
}

export function generateToc(toc: TocItem[]): TocItem[] {
  if (!toc?.length) {
    throw new Error('Table of Contents is empty or failed to load.');
  }

  /* SINGLE CLO or CONTENT ITEM case */
  if (toc.length === 1) {
    if (toc[0].subItems.length > 0) {
      // const subItems = toc[0].subItems;
      return toc[0].subItems.reduce((previous, current) => {
        const item = {
          ...cloneDeep(current),
          subItems: null,
          selfClone: true,
        };
        if (current.subItems) {
          return [
            ...previous,
            {
              ...cloneDeep(current),
              subItems: [item, ...current.subItems],
            },
          ];
        }
        return [...previous, current];
      }, []);
    } else {
      return fillAbsentSubitems(toc);
    }
  } else {
    return fillAbsentSubitems(toc);
  }
}

/** toc item */
export function findClosestTocItem(
  flatToc: Record<number, FlatTocItem>,
  indexes: number[],
): FlatTocItem {
  indexes = indexes.filter((i) => i > -1);

  const tocLookup = indexes.map((i) => flatToc[i]);
  let tocItem = tocLookup.find((item) => Boolean(item)) as FlatTocItem;

  if (!tocItem) {
    let i = indexes?.[0];
    for (i; i >= 0; i--) {
      tocItem = flatToc[i];
      if (tocItem) break;
    }
  }

  return tocItem;
}

/** miscl */
export function fillAbsentSubitems(toc: TocItem[]): TocItem[] {
  return toc.map((item) => {
    const tempSubitem = cloneDeep(item);
    return {
      ...item,
      subItems: [tempSubitem],
    };
  });
}

export function hasActiveItem(item: TocItem, id: string): boolean {
  if (item.id === id) {
    return true;
  } else {
    return item.subItems.some((subItem) => hasActiveItem(subItem, id));
  }
}

export function getAllListElements(
  tree: ElementRef,
): NodeListOf<HTMLLIElement> | [] {
  const rootElement: HTMLElement = tree?.nativeElement;
  return rootElement ? rootElement.querySelectorAll('li') : [];
}

export function resetAriaCurrent(tree: ElementRef): void {
  const treeElements = getAllListElements(tree);

  Array.from(treeElements).forEach((element) => {
    element.setAttribute('aria-current', 'false');
  });
}

export function setAriaCurrentElement(id: string, tree: ElementRef): void {
  resetAriaCurrent(tree);
  let elem: HTMLElement = tree.nativeElement.querySelector(
    `#tree-node-${id}`,
  ) as HTMLElement;
  while (elem) {
    if (elem.nodeName === 'LI') {
      elem.setAttribute('aria-current', 'true');
    }
    elem = elem.parentElement as HTMLElement;
    if (elem.nodeName === 'NGX-BONSAI') {
      break;
    }
  }
}

export function setParentListAriaLabel(tree: ElementRef, label: string): void {
  if (tree?.nativeElement) {
    const elem: HTMLElement = tree.nativeElement.querySelector(
      'ngx-bonsai > ul:first-child',
    ) as HTMLElement;
    elem.setAttribute('aria-labelledby', label);

    elem.querySelectorAll('ul').forEach((ul) => {
      ul.setAttribute('role', 'group');
    });
  }
}
