<div class="drawer-header">
  <h2 translate data-automation-id="search-header-label">search.search</h2>

  <button
    data-automation-id="search-drawer-close"
    placement="bottom"
    mhe-button
    [purpose]="ButtonPurpose.Icon"
    [title]="'shared.close' | translate"
    [mheTooltip]="'shared.close' | translate"
    (click)="closeEvent.emit()"
  >
    <i class="dpg-icon dpg-icon-system-close"></i>
  </button>

  <ng-container *ngIf="searching$ | async">
    <div class="searching-indicator">
      <mat-progress-bar class="rdrx-bar" mode="query"></mat-progress-bar>
    </div>
  </ng-container>
</div>

<div class="drawer-body search-container">
<!--  TODO Uncomment when decide what to do with the new search cause right now the ngx-shared 9 input does not support K5-->
<!--  <div class="form-group">-->
<!--    <input-->
<!--      #searchInput-->
<!--      (input)="handleInput(searchInput.value)"-->
<!--      class="form-control search-input rdrx-hide-label"-->
<!--      data-automation-id="search-input-box"-->
<!--      icon="search"-->
<!--      id="rdx-search-input"-->
<!--      [disabled]="(ready$ | async) === false"-->
<!--      [placeholder]="searchLabel$ | async | translate"-->
<!--      (keydown.arrowdown)="focusResults()"-->
<!--    />-->
<!--    <i aria-hidden="true" class="dpg-icon dpg-icon-search ng-star-inserted"></i>-->
<!--  </div>-->

  <mhe-input
    #searchInput
    class="search-input rdrx-hide-label"
    data-automation-id="search-input-box"
    icon="search"
    id="rdx-search-input"
    [disabled]="(ready$ | async) === false"
    [label]="searchLabel$ | async | translate"
    [placeholder]="searchLabel$ | async | translate"
    (keydown.arrowdown)="focusResults()"
  ></mhe-input>

  <!-- results -->
  <ng-container *ngIf="ready$ | async; else loading">
    <ng-container *ngIf="searchTerm$ | async as term">
      <ng-container *ngIf="(searching$ | async) === false">
        <ng-container *ngIf="results$ | async as results">
          <div *ngIf="results | searchPageNumberResult">
            <div data-automation-id="search-jump-to-page" class="jump-to-page">
              <a href="javascript:void(0)" (click)="selectResult(getPageNumberNode(results))">
                <span>{{ 'search.jump_to_page' | translate }}</span>
                <span>{{ (results | searchPageNumberResult).text }}</span>
              </a>
            </div>
          </div>

          <div class="results">
            <div class="result-count" data-automation-id="search-result-count">
              <ng-container *ngIf="results.length > 0; else refine">
                <ng-container *ngIf="results.length <= 1000; else limitedResults" role="status">
                  {{ results.length }}
                  {{ (results.length === 1 ? 'search.result' : 'search.results') | translate | titlecase }}
                </ng-container>

                <!-- limited results -->
                <ng-template #limitedResults>
                  <ng-container>
                    <span class="too-many"
                      ><span class="sr-only">{{ 'assignment.warning_title' | translate }}</span>
                      {{ 'search.limited_results' | translate }}</span
                    >
                  </ng-container>
                </ng-template>
              </ng-container>

              <ng-template #refine>
                <p *ngIf="results.length === 0">
                  {{ 'search.refine_search' | translate }}
                </p>
              </ng-template>
            </div>

            <!-- result 'tree' -->
            <ngx-bonsai
              #searchBonsai
              rdrxBonsaiTabIndex
              class="rdrx"
              [nodesById]="tree$ | async"
              [rootNodeId]="rootNodeId"
              [expandedNodes]="expandedNodes$ | async"
              (expanderClick)="toggleNodeExpanded($event)"
            >
              <ng-template #treeNodeTemplate let-node>
                <!-- result -->
                <ng-container *ngIf="node.searchResult as result; else group">
                  <a
                    mheStopPropagation
                    rdrxBonsaiNodeSelect
                    data-automation-id="search-result-item"
                    href="javascript:void(0)"
                    class="rdrx-node-content ahe-ui-link"
                    tabindex="-1"
                    [innerHTML]="result | searchResult: term"
                    (click)="selectResult(node)"
                  ></a>
                </ng-container>
                <!-- group header -->
                <ng-template #group>
                  <a
                    mheStopPropagation
                    rdrxBonsaiNodeSelect
                    data-automation-id="search-result-group"
                    href="javascript:void(0)"
                    class="rdrx-node-content ahe-ui-link"
                    tabindex="-1"
                    [rdrxBonsaiNodeSelectKeyboard]="false"
                    (click)="toggleNodeExpanded(node.id)"
                  >
                    {{ node.title }}
                  </a>
                </ng-template>
              </ng-template>
            </ngx-bonsai>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- loading -->
  <ng-template #loading>
    <div class="loading-container">
      <mat-progress-spinner
        class="rdrx-spinner"
        mode="determinate"
        [strokeWidth]="2"
        [diameter]="75"
        [value]="downloadPercent$ | async"
      ></mat-progress-spinner>

      <h3 class="loading-percent">{{ downloadPercent$ | async }}%</h3>
      <h3 class="loading-message">{{ 'search.loading_search' | translate }}</h3>

      <span translate class="sr-only" aria-live="polite">search.loading_search</span>
    </div>
  </ng-template>
</div>
