<div class="viewer-container" role="dialog" aria-labelledby="print-modal-h2">
  <rdrx-modal-header (closeEvent)="dialogRef.close()">
    <h2 class="modal-header" id="print-modal-h2">
      {{ "dialog.title.print" | translate }}
    </h2>
  </rdrx-modal-header>

  <div class="body">
    <div class="body__header">
      <p class="piracy-statement">{{"print.piracyStatement" | translate}}</p>
      <p>{{"print.select" | translate}}</p>
      <hr class="header-divider" />
    </div>


    <div *ngFor="let chapter of data.chapters" mheRadioGroup role="radiogroup"
      (radioGroupChange)="updateSelection($event)" [value]="selectedChapter" class="chapter-list-item"
      (click)="updateSelection($event)">
      <mhe-radio-button [value]="chapter" [checked]="selectedChapter === chapter.name">
        <span class="radio-option-text">{{ chapter.name }}</span>
      </mhe-radio-button>
      <hr />
    </div>
  </div>

  <!-- footer -->
  <rdrx-modal-footer class="modal-footer" data-automation-id="modal-footer">
    <div class="modal-footer-button">
      <button 
        data-automation-id="cancel-print-button" 
        class="btn btn-secondary" 
        (click)="dialogRef.close()"
      >
        {{ "dialog.cancel" | translate }}
      </button>
      <button 
        data-automation-id="continue-print-button" 
        mhe-button class="btn btn-primary"
        [purpose]="ButtonPurpose.Primary" 
        (click)="dialogRef.close(selectedChapter)" 
        [disabled]="!selectedChapter"
      >
        {{ "dialog.continue" | translate }}
      </button>
    </div>
  </rdrx-modal-footer>
</div>