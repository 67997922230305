<div class="controls" [attr.aria-label]="'navigation.reading_navigation' | translate">
  <reader-core-previous-button
    *ngIf="showControls"
    class="item"
    [interfaceMode]="interfaceMode"
    [navigationType]="navigationType"
    [disabled]="disablePrevious$ | async"
    [chapterLabel]="previousChapterLabel$ | async"
    (reverse)="navigateByStep(-1)"
  ></reader-core-previous-button>

  <reader-core-position-indicator
    *ngIf="showPosition"
    class="item"
    [lower]="pageNumber$ | async"
    [upper]="positionUpper$ | async"
  ></reader-core-position-indicator>

  <reader-core-forward-button
    *ngIf="showControls"
    class="item"
    [interfaceMode]="interfaceMode"
    [navigationType]="navigationType"
    [disabled]="disableNext$ | async"
    [chapterLabel]="nextChapterLabel$ | async"
    (forward)="navigateByStep(1)"
  ></reader-core-forward-button>
</div>
